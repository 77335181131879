<template>
  <div class="all">
    <Nav></Nav>
    <div class="about">
      <div class="catalogtitle">
        <div class="icon"></div>
        首页>>{{ catalogTitle }}>>详情
      </div>
      <div class="announce-list">
        <div class="announce-con">
          <div class="annoce-title">
            <div class="title">{{ activityInfo.title }}</div>
            <div class="time">{{ activityInfo.createTime }}</div>
          </div>
          <div class="annoce-nav">
            <!-- <div class="img" :style="{ backgroundImage: `url(` + imgurl + `)` }"></div> -->
            <img :src="activityInfo.imgurl" />
            <div class="text">
              <!-- <div class="detail-text">{{ activityInfo.text }}</div> -->
              <div class="rich-text-preview" v-html="activityInfo.text"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>
<script>
// @ is an alias to /src
import RD from "@/api/RD";
import Nav from "@/components/nav.vue";
import Bottom from "@/components/bottom.vue";
export default {
  name: "Home",
  components: { Nav, Bottom },
  data: function () {
    return {
      activityInfo: {}, // 公告的类型
      catalogTitle: "", // 公告的名称
      imgurl: "",
    };
  },
  created() {
    this.reload();
  },
  beforeRouteUpdate(to, from, next) {
    if (to.fullPath != from.fullPath) {
      next();
      this.reload();
    }
  },
  methods: {
    reload() {
      var thiz = this;
      console.log("78", this.$route.query);
      switch (parseInt(this.$route.query.type)) {
        case 1:
          thiz.catalogTitle = "公告";
          break;
        case 2:
          thiz.catalogTitle = "品牌服务";
          break;
        case 3:
          thiz.catalogTitle = "视觉动态";
          break;
      }
      RD.notice()
        .id(this.$route.query.id)
        .one()
        .then((data) => {
          console.log("ad", data);
          data.imgurl = RD.notice().id(data.id).noticeImage_URL();
          thiz.activityInfo = data;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.all {
  height: auto;
  background: #f5f5f5;
}
.about {
  .catalogtitle {
    height: 4rem;
    text-align: left;
    width: 120rem;
    margin: 0 auto;
    font-size: 1.2rem;
    padding-top: 1.5rem;
    display: flex;
    border-bottom: 0.1rem solid #bfbfbf;
    .icon {
      width: 2rem;
      height: 2rem;
      background: no-repeat url(../assets/weizhi.png);
      background-size: cover;
      margin-right: 1rem;
    }
  }
  .announce-list {
    width: 120rem;
    margin: 0 auto;
    .announce-con {
      width: 100rem;
      margin: 0 auto;
      background: #fff;
      .annoce-title {
        font-size: 2.5rem;
        border-bottom: 0.2rem solid #ccc;
        height: 9.5rem;
        .title {
          width: 90rem;
          margin: 0 auto;
          padding-top: 2rem;
        }
        .time {
          width: 90rem;
          margin: 1rem auto;
          font-size: 1.5rem;
          text-align: left;
          height: 2rem;
          line-height: 2rem;
        }
      }
      .annoce-nav {
        width: 90rem;
        margin: 2rem auto;
        img {
          width: 100%;
          height: 100%;
          background: no-repeat 50% / cover;
        }
        .text {
          margin-top: 2rem;
          .detail-text {
            font-size: 1.3rem;
            text-align: left;
            line-height: 3rem;
            letter-spacing: 0.2rem;
            white-space: pre-wrap;
            word-spacing: 1em;
            // text-indent: 2em;
            letter-spacing: 0.2rem;
            line-height: 3.5rem;
          }
          .rich-text-preview {
            // max-height: 60vh;
            overflow-y: auto;
            ::v-deep p {
              text-align: left;
              line-height: 40px;
              font-size: 18px;
              word-spacing: 0.25em;
            }
            ::v-deep pre {
              white-space: pre-wrap;
            }
          }
        }
      }
    }
  }
}
</style>
